import React from 'react'
import './header.scss'
import loadable from '@loadable/component'
import ImageSlider from '../shared/ImageSlider'
import Marked from 'react-markdown'
const Fade = loadable(() => import('../transition/Fade'))

const Header = (props) => {
  return (
    <div className="team-header-section">
      <div className="header-content d-lg-flex" id="common-px">
        <div className="title-container">
          <p className="sub-title">Our</p>
          <Fade timeout={500}>
            <h1>Team</h1>
          </Fade>
          <Fade timeout={1000}>
            <p className="title-dec">
              <Marked parserOptions={{ commonmark: true}} source={props.ourTeamData.frontmatter.description} />
            </p>
          </Fade>
        </div>
        <ImageSlider gallery={props.ourTeamData.frontmatter.gallery}/>
      </div>
      <div className="bg-text-container">
        <img
          src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614325549/TEAM_l4pam4.png"
          alt="text text"
          className="img-fluid"
        />
      </div>
    </div>
  )
}

export default Header
