import React, { useState } from 'react'

import './ImageSlider.scss'

const ImageSlider = ({gallery}) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(1)
    const [nextDisplayingImage, setNextImageIndex] = useState(gallery && gallery.length > 1 ?  gallery[1] : {})
    const [displayingImage, setDisplayingImageIndex] = useState(gallery && gallery.length > 1 ?  gallery[0] : {})
    const renderImage = (
    <div className="img-parent-container">
        <img src={nextDisplayingImage.image} alt={nextDisplayingImage.alt} className="about-us-img" />
        <img src={displayingImage.image} alt={displayingImage.alt} className="about-us-img" />
    </div>
    )
    const changeImages = () => {
        if(nextIndex + 1  < (gallery.length)){
            let displayingIndex = nextIndex + 1
            const index = gallery.findIndex(({image}) => image === gallery[nextIndex].image);
            setCurrentIndex(index)
            setNextIndex(displayingIndex)
            setDisplayingImageIndex(gallery[index])
            setNextImageIndex(gallery[displayingIndex])
        } else if(nextIndex + 1  === (gallery.length)) {            
            const index = gallery.findIndex(({image}) => image === gallery[nextIndex].image);
            setCurrentIndex(index)
            setNextIndex(0)
            setDisplayingImageIndex(gallery[index])
            setNextImageIndex(gallery[0])
        } else {
            setCurrentIndex(0)
            setNextIndex(1)
            setDisplayingImageIndex(gallery[0])
            setNextImageIndex(gallery[currentIndex + 1])
        }  
    }
    const renderButton = (
        <div className="about-project-btn-container">
            <button
                className="about-project-btn"
                onClick={changeImages}
            >
                Next
                <svg
                className="angle"
                viewBox="0 0 12 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    d="M2.12477 19.8002L11.4636 10.4607C11.5972 10.3273 11.6641 10.1738 11.6641 10.0001C11.6641 9.82645 11.5972 9.67266 11.4636 9.53915L2.12477 0.200471C1.99098 0.0666829 1.83747 -4.29534e-07 1.66375 -4.37128e-07C1.49009 -4.44719e-07 1.33637 0.0668934 1.20286 0.200471L0.201136 1.20226C0.0675582 1.33584 0.000804836 1.48956 0.000804828 1.66322C0.000804821 1.83688 0.0675582 1.9906 0.201136 2.12418L8.07692 10.0001L0.200643 17.8763C0.0670654 18.0098 0.000734489 18.1636 0.000734481 18.337C0.000734474 18.5109 0.0674869 18.6646 0.200643 18.7982L1.20279 19.8001C1.3363 19.9336 1.49009 19.9999 1.66367 19.9999C1.83747 20 1.99119 19.9337 2.12477 19.8002Z"
                    fill="#101010"
                />
                </svg>
            </button>
        </div>
    )
    return (
        <div className="img-content">
            <div className="number-container">
                <p className="vertical-text">
                {currentIndex + 1} / {gallery.length}
                </p>
            </div>
            <div className="img-container">{renderImage}</div>
            <div className="btn-container">{renderButton}</div>
        </div>
    )
}

export default ImageSlider